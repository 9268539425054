import React, { useMemo } from 'react';
import { ModConfig, SelectInputOptionType, Carousel, SvgNoSymbol } from 'mod-styleguide';
import { TextureOption } from '../texture-option';
import './texture-picker.scss';

type Props = {
  textures: SelectInputOptionType[],
  selectedTexture: SelectInputOptionType,
  setSelectedTexture: (texture: SelectInputOptionType) => void,
  onApplyTexture: (texture: SelectInputOptionType, selectedBodyParts: SelectInputOptionType[]) => void,
  headerTitle: string,
  bodyParts: SelectInputOptionType[],
  activeModConfigs: Array<ModConfig>,
  slidesToScroll?: number,
  useDots?: boolean,
  selectAllLabel?: string,
  onClear?: () => void,
  useCarousel?: boolean,
};

export const TexturePicker = ({
  textures,
  selectedTexture,
  setSelectedTexture,
  onApplyTexture,
  headerTitle,
  bodyParts,
  activeModConfigs,
  slidesToScroll,
  useDots,
  selectAllLabel,
  useCarousel,
  onClear,
}: Props) => {
  const textureOptions = useMemo(() => {
    const options = textures?.map((texture) => (
      <TextureOption
        key={texture.label + texture.value}
        texture={texture}
        bodyParts={bodyParts}
        isSelected={selectedTexture?.id === texture.id}
        headerTitle={headerTitle}
        onClick={setSelectedTexture}
        onApplyTexture={onApplyTexture}
        activeModConfigs={activeModConfigs}
        selectAllLabel={selectAllLabel}
      />
    ));

    if (onClear) {
      options.unshift((
        <SvgNoSymbol
          key="clear"
          onClick={() => {
            setSelectedTexture(null);
            onClear();
          }}
        />
      ));
    }
    return options;
  }, [
    textures,
    onClear,
    bodyParts,
    selectedTexture?.id,
    headerTitle,
    setSelectedTexture,
    onApplyTexture,
    activeModConfigs,
    selectAllLabel,
  ]);

  return (
    <div className='wrap-picker-rct-component__texture-picker'>
      {useCarousel ? (
        <Carousel
          slidesToScroll={slidesToScroll}
          slides={textureOptions}
          containScroll="trimSnaps"
          dragFree
          useArrows
          useDots={useDots}
          dotsPrefixLabel={selectedTexture?.label}
        />
      ) : (
        <div className='wrap-picker-rct-component__texture-picker__options-no-carousel'>
          {textureOptions}
        </div>
      )}
    </div>
  );
};

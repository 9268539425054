import { defineMessages } from 'react-intl';

export const ORDER_FORM = defineMessages({
  ORDER_FORM_TITLE: {
    defaultMessage: 'Pre-Order Survey',
    id: 'order-form.title',
  },
  ORDER_FORM_SUB_TITLE: {
    defaultMessage: 'MOD is not quite ready to take orders yet. Please complete the survey below to get a 5% discount on your first MOD order when available.',
    id: 'order-form.sub-title',
  },
  ORDER_FORM_SUB_TITLE_V2: {
    defaultMessage: 'Please enter your email address, we will reach out to you to finalize your order. Note that the MOD service is only available in the Honolulu, HI area at this time.',
    id: 'order-form.sub-title-v2',
  },
  ORDER_FORM_SUBMIT: {
    defaultMessage: 'Start Survey',
    id: 'order-form.submit',
  },
});

import React, { useCallback } from 'react';

import { Modal, Form, MESSAGE_TYPES } from 'mod-styleguide';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { orderFormState } from 'redux/modules/forms';
import { useAppDispatch, useAppSelector } from 'lib/types/redux';
import { onOrderFormChangeV2 } from 'redux/modules/forms/order/orderSlice';
import { emitFlashMessage } from 'redux/modules/flashMessages/flashMessagesSlice';
import { ModalProps } from 'mod-styleguide/src/components/modal/modal';
import { carConfiguratorSelector, selectActiveModV2Config, selectActiveVehicleConfigPrice } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { useCreateOrderV2Mutation } from 'redux/modules/api';

interface OrderFormModalProps extends ModalProps {
}

export const OrderFormModalV2: React.FC<OrderFormModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const formState = useSelector(orderFormState);
  const dispatch = useAppDispatch();
  const activeConfig = useAppSelector(selectActiveModV2Config);
  const { displayInfo } = useAppSelector(carConfiguratorSelector);
  const vehicleConfigPrice = useAppSelector(selectActiveVehicleConfigPrice);
  const [createOrder] = useCreateOrderV2Mutation();

  const onSubmit = useCallback(() => {
    window.gtag('event', 'survey', {
      currency: 'USD',
      value: vehicleConfigPrice,
      config_id: 0,
    });

    createOrder({
      email: formState.email,
      vehicleName: `${displayInfo?.make} ${displayInfo?.model} ${displayInfo?.year}`,
      modConfig: activeConfig,
      currency: 'USD',
      price: 1500,
    });

    dispatch(emitFlashMessage({
      message: formatMessage({
        id: 'common.order-submitted',
      }),
      type: MESSAGE_TYPES.SUCCESS,
    }));
    onClose();
  }, [dispatch, onClose, formatMessage, formState, vehicleConfigPrice, createOrder, activeConfig, displayInfo]);

  const onInputChange = useCallback((e) => {
    dispatch(onOrderFormChangeV2(e));
  }, [dispatch]);

  return (
    <div>
      <Modal
        className='order-form-modal-rct-component'
        isOpen={isOpen}
        onClose={onClose}
        title={formatMessage({
          id: 'common.order',
        })}
        subTitle={formatMessage({
          id: 'order-form.sub-title-v2',
        })}
      >
        <Form
          formState={formState}
          onInputChange={onInputChange}
          submitLabel="Order"
          onSubmit={onSubmit}
          formFieldDefs={[
            {
              name: 'email',
              autoComplete: 'email',
              label: formatMessage({
                id: 'common.email-address',
              }),
              placeholder: formatMessage({
                id: 'form.email-placeholder',
              }),
            },
          ]}
        />
      </Modal>
    </div>
  );
};


import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { useCallback, useEffect, useState } from 'react';

interface S3Asset {
  key: string;
  url: string;
}

export interface WrapIcons {
  colorIcons: S3Asset[];
  designIcons: S3Asset[];
}
const assetBaseUrl = 'https://assets.modcars-dev.com/';

export const useWrapIcons: () => WrapIcons = () => {
  const [colorIcons, setColorIcons] = useState<S3Asset[]>([]);
  const [designIcons, setDesignIcons] = useState<S3Asset[]>([]);

  const fetchColorIcons = useCallback(() => {
    const client = new S3Client({
      region: 'us-east-1',
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: 'us-east-1' },
        identityPoolId: 'us-east-1:8b8b61d7-30f8-4e12-b26c-008d317004e9',
      }),
    });

    const command = new ListObjectsCommand({
      Bucket: 'mod-dev-global-assets',
      Prefix: 'mod_v2/icons',
    });
    client.send(command).then(({ Contents }) => {
      const matchingObjects = [];
      Contents.forEach((object) => {
        if (object.Key.endsWith('.jpg') || object.Key.endsWith('.png')) {
          matchingObjects.push({
            key: object.Key,
            url: `${assetBaseUrl}${object.Key}`,
          });
        }
      });

      setColorIcons(
        matchingObjects.filter((object) => !object.key.includes('design')),
      );
      setDesignIcons(
        matchingObjects.filter((object) => object.key.includes('design')),
      );
    },
    );
  }, []);

  useEffect(() => {
    fetchColorIcons();
  }, [fetchColorIcons]);

  return {
    colorIcons,
    designIcons,
  };
};

import React, { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import './carousel.scss';
import styled from 'styled-components';
import { Visible } from '@codeparticle/react-visible';
import classNames from 'classnames';
import { SvgChevronLeftNav } from '../../assets';

const SlideContainer = styled.div(props => ({
  flex: '0 0 100%',
  maxWidth: `calc(100% / ${props.slidesToScroll})`,
}));

export const DotButton = ({ selected, onClick }) => (
  <button
    className={`carousel__dot ${selected ? 'selected' : ''}`}
    data-testid="dot-button"
    type="button"
    onClick={onClick}
  />
);

interface CarouselProps extends EmblaOptionsType {
  slides: string[] | JSX.Element[];
  useArrows?: boolean;
  hideUnnecessaryArrows?: boolean;
  useDots?: boolean,
  dotsPrefixLabel?: string,
  className?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  slides,
  useDots,
  useArrows,
  hideUnnecessaryArrows,
  dotsPrefixLabel,
  className,
  ...emblaOptions
}) => {
  const [emblaRef, embla] = useEmblaCarousel({
    ...emblaOptions,
  });

  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla,
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const navigate = useCallback((direction: 'left' | 'right') => {
    if (!embla) return;
    if (direction === 'left') {
      embla.scrollPrev();
    } else {
      embla.scrollNext();
    }
  }, [embla]);

  if (!slides) {
    return null;
  }

  const hideLeftArrow = hideUnnecessaryArrows && selectedIndex === 0;
  const hideRightArrow = hideUnnecessaryArrows && selectedIndex === slides.length - 1;

  return (
    <>
      <div className={`carousel ${className}`}>
        <Visible when={useArrows && !hideLeftArrow}>
          <SvgChevronLeftNav
            className={classNames(
              'navigation-arrow navigation-arrow__left',
            )}
            data-testid="left-nav-arrow"
            onClick={() => navigate('left')}
          />
        </Visible>
        <div className="carousel__embla-ref" ref={emblaRef}>
          <div className="carousel__slides-container">
            {slides?.map((slide, index) => (
              <SlideContainer key={index} slidesToScroll={emblaOptions.slidesToScroll}>
                <div data-testid="slide-item" className="embla__slide__inner">{slide}</div>
              </SlideContainer>
            ))}
          </div>
        </div>
        <Visible when={useArrows && !hideRightArrow}>
          <SvgChevronLeftNav
            className={classNames(
              'navigation-arrow navigation-arrow__right',
            )}
            data-testid="right-nav-arrow"
            onClick={() => navigate('right')}
          />
        </Visible>
      </div>
      <Visible when={useDots}>
        <div className="carousel__dots" data-testid="carousel-dots-container">
          <span> {dotsPrefixLabel} </span>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </Visible>
    </>
  );
};

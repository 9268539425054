const DEFAULT_CAROUSEL_ITEMS_PER_SLIDE = 18;
const WIDE_CAROUSEL_ITEMS_PER_SLIDE = 12;
const DESKTOP_HD_ITEMS_PER_SLIDE = 10;
const TABLET_ITEMS_PER_SLIDE = 8;
const MOBILE_CAROUSEL_ITEMS_PER_SLIDE = 4;

export const getItemsPerSlide = (isWidescreen, isDesktopHDOrSmaller, isTabletOrSmaller, isMobile) => {
  if (isMobile) {
    return MOBILE_CAROUSEL_ITEMS_PER_SLIDE;
  }
  if (isTabletOrSmaller) {
    return TABLET_ITEMS_PER_SLIDE;
  }
  if (isDesktopHDOrSmaller) {
    return DESKTOP_HD_ITEMS_PER_SLIDE;
  }
  if (isWidescreen) {
    return WIDE_CAROUSEL_ITEMS_PER_SLIDE;
  }
  return DEFAULT_CAROUSEL_ITEMS_PER_SLIDE;

};

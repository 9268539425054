import React from 'react';
import './selected-vehicle-info-field.scss';

export const SelectedVehicleInfoField = ({
  title,
  value,
}: {
  title?: string;
  value: string;
}) => {

  return (
    <div className='selected-vehicle-info-field-v2'>
      {title && (
        <span className='selected-vehicle-info-field-v2__label'>{title}</span>
      )}
      <span className='selected-vehicle-info-field-v2__value'>{value}</span>
    </div>
  );
};

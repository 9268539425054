import { createSlice, current } from '@reduxjs/toolkit';
import { VEHICLE_MODELS } from 'components/car-configurator-v2/components/selected-vehicle-info/selected-vehicle-info';
import { mapModConfigsFromSelectedWrapData } from 'lib/data-mappers';
import type { CarConfiguratorState, VehicleConfig, VehicleModel, VehicleDisplayInfo, VehicleModelSelectableOptions, VehicleModelV2, VehicleDisplayInfoV2 } from 'lib/types';
import { ModConfig, SelectedWrapData } from 'mod-styleguide';

const mountDisplayInfoFromSelectedOptions = (selectedOptions: VehicleModelSelectableOptions): VehicleDisplayInfo => {
  const { year, make, model, trim, color } = selectedOptions;
  return {
    year: year?.label,
    make: make?.label,
    model: model?.label,
    trim: trim?.label,
    color: color?.label,
  };
};

const INITIAL_STATE = {
  initialized: false,
  vehicleModelData: {
    vehicle3dModelUrl: null,
    bodyParts: [],
    options: {
      trims: [],
      colors: [],
    },
  },
  displayInfo: null,
  vehicleConfig: {
    vehicleModel: null,
    options: {
      trim: null,
      color: null,
    },
    modConfigs: [],
  },
  activeVehicleConfigId: null, // TODO - consider moving these to vehicleConfig object as optional properties
  activeVehicleConfigPrice: null,
  activeWraps: null,
} as CarConfiguratorState;

export const carConfiguratorSlice = createSlice({
  name: 'carConfigurator',
  initialState: INITIAL_STATE,
  reducers: {
    initializeCarConfigurator: (state,
      { payload: { defaultVehicle } } : { payload: { defaultVehicle: VehicleModel }
      }) => {
      state.initialized = true;
      const { selectedOptions } = defaultVehicle as VehicleModel;

      carConfiguratorSlice.caseReducers.selectConfiguratorVehicle(state, {
        payload: {
          vehicleModel: defaultVehicle,
          selectedOptions,
        },
      });
    },
    initializeCarConfiguratorV2: (state) => {
      const defaultVehicleModel = VEHICLE_MODELS.Tacoma24;
      const defaultModConfigs = [{
        bodyPart: 'body-part-full',
        mod: 'colors/cool-teal',
      }];
      carConfiguratorSlice.caseReducers.selectConfiguratorVehicleV2(state, {
        payload: {
          vehicleModel: defaultVehicleModel,
          modConfigs: defaultModConfigs,
        },
      });

      state.initialized = true;
    },
    selectConfiguratorVehicle: (state, { payload: {
      vehicleModel,
      selectedOptions,
      modConfigs,
    } } : { payload: { vehicleModel: VehicleModel, selectedOptions: VehicleModelSelectableOptions, modConfigs?: ModConfig[] } }) => {
      state.vehicleModelData = {
        ...vehicleModel,
        selectedOptions,
      };
      const hasVehicleModelChanged = state.vehicleConfig.vehicleModel !== vehicleModel.slug;

      const getFinalModConfigs = () => {
        if (modConfigs) {
          return modConfigs;
        }
        if (!hasVehicleModelChanged) {
          return state.vehicleConfig.modConfigs;
        }
        return [];
      };

      const finalModConfigs = getFinalModConfigs();

      if (hasVehicleModelChanged) {
        carConfiguratorSlice.caseReducers.resetVehicleConfig(state);
      }
      carConfiguratorSlice.caseReducers.setDisplayInfo(state, {
        payload: mountDisplayInfoFromSelectedOptions(selectedOptions),
      });
      carConfiguratorSlice.caseReducers.updateVehicleConfig(state, {
        payload: {
          vehicleModel: selectedOptions.model.id || vehicleModel.slug,
          options: {
            trim: selectedOptions.trim.id,
            color: selectedOptions.color.id,
          },
          modConfigs: finalModConfigs,
        },
      });
    },
    selectConfiguratorVehicleV2: (state, { payload: {
      vehicleModel,
      modConfigs,
    } } : { payload: { vehicleModel: VehicleModelV2, modConfigs?: ModConfig[] } }) => {
      state.vehicleModelDataV2 = {
        ...vehicleModel,
      };
      const hasVehicleModelChanged = state.displayInfo?.model !== vehicleModel.model || state.displayInfo?.year !== vehicleModel.year;

      const getFinalModConfigs = () => {
        if (modConfigs) {
          return modConfigs;
        }
        if (hasVehicleModelChanged) {
          return state.vehicleConfig.modConfigs;
        }
        return [];
      };

      const finalModConfigs = getFinalModConfigs();

      if (hasVehicleModelChanged) {
        carConfiguratorSlice.caseReducers.resetVehicleConfig(state);
      }
      carConfiguratorSlice.caseReducers.setDisplayInfoV2(state, {
        payload: vehicleModel,
      });
      carConfiguratorSlice.caseReducers.updateVehicleConfig(state, {
        payload: {
          vehicleModel: vehicleModel.model,
          modConfigs: finalModConfigs,
        },
      });
    },
    updateVehicleModelSelectedOptions: (state, { payload: {
      year,
      make,
      model,
      trim,
      color,
    } }) => {
      state.vehicleModelData.selectedOptions = {
        year,
        make,
        model,
        trim,
        color,
      };
    },
    setDisplayInfo: (state,
      {
        payload: {
          year, make, model, trim, color,
        } } : { payload: VehicleDisplayInfo }) => {
      state.displayInfo = {
        year,
        make,
        model,
        trim,
        color,
      };
    },
    setDisplayInfoV2: (state,
      {
        payload: {
          make,
          model,
          year,
        } } : { payload: VehicleDisplayInfoV2 }) => {
      state.displayInfo = {
        make,
        model,
        year,
        trim: '',
        color: '',
      };
    },
    removeWrapMod: (state, { payload: { bodyPart } }: { payload: { bodyPart: string } }) => {
      const currentModConfigs = current(state.vehicleConfig.modConfigs);
      const finalModConfigs = currentModConfigs.filter((modConfig) => modConfig.bodyPart !== bodyPart);
      carConfiguratorSlice.caseReducers.updateVehicleConfig(state, {
        payload: {
          modConfigs: finalModConfigs,
        },
      });
    },
    applyWrapMod: (state, { payload: { wrapData, singleWrap } }: { payload: { wrapData: SelectedWrapData, singleWrap?: boolean } }) => {
      const newModConfigs = mapModConfigsFromSelectedWrapData(wrapData);
      const currentModConfigs = current(state.vehicleConfig.modConfigs);

      const getFinalModConfigs = () => {
        const finalModConfigs = singleWrap ? [] : [...currentModConfigs];

        if (singleWrap) {
          finalModConfigs.push(...newModConfigs);
        } else {
          newModConfigs.forEach((newModConfig) => {
            const existingBodyPartModIndex = finalModConfigs.findIndex((currentModConfig) => currentModConfig.bodyPart === newModConfig.bodyPart);
            const modAlreadyInPlace = finalModConfigs.some((currentModConfig) => currentModConfig.bodyPart === newModConfig.bodyPart && currentModConfig.mod === newModConfig.mod);

            if (modAlreadyInPlace) {
              // Mod Already in place, remove it
              finalModConfigs.splice(existingBodyPartModIndex, 1);
            } else if (existingBodyPartModIndex !== -1) {
              // Mod not in place, but body part already has a mod, replace it
              finalModConfigs[existingBodyPartModIndex] = newModConfig;
            } else {
              // Mod not in place, body part doesn't have a mod, add it
              finalModConfigs.push(newModConfig);
            }
          });
        }
        return finalModConfigs;
      };

      const finalModConfigs = getFinalModConfigs();

      carConfiguratorSlice.caseReducers.updateVehicleConfig(state, {
        payload: { modConfigs: finalModConfigs },
      });
    },
    applyWrapModV2: (state, { payload: { wrapData } }: { payload: { wrapData: SelectedWrapData } }) => {
      const newModConfigs = [mapModConfigsFromSelectedWrapData(wrapData)[0]];

      carConfiguratorSlice.caseReducers.updateVehicleConfig(state, {
        payload: { modConfigs: newModConfigs },
      });

      state.activeVehicleConfigPrice = 1500;

    },
    updateVehicleConfig: (state, {
      payload,
    } : { payload: Partial<VehicleConfig> },
    ) => {
      state.vehicleConfig = {
        ...state.vehicleConfig,
        ...payload,
      };
    },
    setActiveVehicleConfigId: (state, { payload: vehicleConfigId }) => {
      state.activeVehicleConfigId = vehicleConfigId;
    },
    setActiveVehicleConfigPrice: (state, { payload: vehicleConfigPrice }) => {
      state.activeVehicleConfigPrice = vehicleConfigPrice;
    },
    resetVehicleConfig: (state) => {
      state.vehicleConfig = INITIAL_STATE.vehicleConfig;
    },
  },
});

export const {
  removeWrapMod,
  applyWrapMod,
  applyWrapModV2,
  updateVehicleConfig,
  setDisplayInfo,
  initializeCarConfigurator,
  initializeCarConfiguratorV2,
  selectConfiguratorVehicle,
  selectConfiguratorVehicleV2,
  setActiveVehicleConfigId,
  setActiveVehicleConfigPrice,
  resetVehicleConfig,
} = carConfiguratorSlice.actions;
export const carConfiguratorSelector = state => state.carConfigurator as CarConfiguratorState;
export const selectConfiguratorVehicle3dModelUrl = state => state.carConfigurator.vehicleModelData.vehicle3dModelUrl;
export const selectActiveVehicleConfigId = state => state.carConfigurator.activeVehicleConfigId;
export const selectActiveVehicleConfigPrice = state => state.carConfigurator.activeVehicleConfigPrice;
export const selectActiveModConfigs = state => state.carConfigurator.vehicleConfig.modConfigs;
export const selectActiveModV2Config = state => state.carConfigurator.vehicleConfig.modConfigs[0];
export const selectConfiguratorVehicleOptions = state => state.carConfigurator.vehicleConfig.options;
export const selectConfiguratorVehicleBodyParts = state => state.carConfigurator.vehicleModelData.bodyParts;
export default carConfiguratorSlice.reducer;

import React, { useCallback } from 'react';
import './simple-car-configurator-v2.scss';
import { Header, SelectInputOptionType, WrapPicker } from 'mod-styleguide';
import { translate } from 'lib/utils/intl-utils';
import { useMedia } from 'lib/hooks';
import { Visible } from '@codeparticle/react-visible';
import { sentence } from 'case';
import { useAppSelector } from 'lib/types/redux';
import { carConfiguratorSelector } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import { SelectedVehicleInfo } from '../components/selected-vehicle-info';
import { CarConfiguratorHeader } from '../components/car-configurator-header';
import { useWrapPicker } from '../hooks';
import { getItemsPerSlide } from '../config';
import { CarConfiguratorCarousel } from './car-configurator-carousel';
import { useCarouselImages } from '../hooks/use-carousel-images';

export const SimpleCarConfiguratorV2: React.FC = () => {
  const {
    wrapPickerData,
    wrapPickerDefaultStates,
    onApplyWrap,
    setWrapPickerDefaultStates,
  } = useWrapPicker();
  const { isMobile, isDesktopHDOrSmaller, isTabletOrSmaller, isWideScreen } = useMedia();
  const { vehicleConfig } = useAppSelector(carConfiguratorSelector);

  const {
    carouselImages,
  } = useCarouselImages();

  const selectedVehicleInfo = (
    <SelectedVehicleInfo />
  );

  const onMaterialListOpen = useCallback(() => {
    window.gtag('event', 'view_mod_materials_list', {
      interaction_type: 'open',
    });
  }, []);

  const onMaterialChange = useCallback((brand: SelectInputOptionType, material: SelectInputOptionType) => {
    window.gtag('event', 'select_mod_material', {
      'material_id': material.id,
      'material_name': material.label,
      'material_brand': brand.label,
    });
  }, []);

  const onTextureChange = useCallback((material: SelectInputOptionType, texture: SelectInputOptionType) => {
    window.gtag('event', 'select_mod_color', {
      'mod_color_id': texture.id,
      'mod_color_name': texture.label,
      'material_name': material.label,
    });
  }, []);

  const wrapPicker = wrapPickerDefaultStates.defaultMaterial && wrapPickerDefaultStates.defaultBrand && wrapPickerData && (
    <WrapPicker
      materialsGroupedOptions={wrapPickerData.materialsGroupedOptions}
      textures={wrapPickerData.textures}
      defaultBrand={wrapPickerDefaultStates.defaultBrand}
      defaultMaterial={wrapPickerDefaultStates.defaultMaterial}
      defaultTexture={wrapPickerDefaultStates.defaultTexture}
      allMaterialsTextures={wrapPickerData.allMaterialsTextures}
      setDefaultPickerData={setWrapPickerDefaultStates}
      activeModConfigs={vehicleConfig.modConfigs}
      labels={{
        textureLabel: sentence(translate('common.color')),
      }}
      bodyParts={[
        { id: 'body-part-full', label: translate('car-configurator.full-body'), hidden: true },
      ]}
      onApply={onApplyWrap}
      carouselSlidesToScroll={getItemsPerSlide(isWideScreen, isDesktopHDOrSmaller, isTabletOrSmaller, isMobile)}
      onMaterialListOpen={onMaterialListOpen}
      onTextureChange={onTextureChange}
      onMaterialChange={onMaterialChange}
    />
  );

  return (
    <div className="simple-car-configurator-v2-rct-component">
      <div className='simple-car-configurator-v2-rct-component__main-content'>
        <Header
          transparent
          customContent={
            <CarConfiguratorHeader
              wrapPicker={!isMobile && wrapPicker}
              disableActions
            />
          }
          hideLogo={isDesktopHDOrSmaller}
        />
        <div
          className='simple-car-configurator-v2-rct-component__scene-container'
        >
          <Visible when={!isMobile}>
            {selectedVehicleInfo}
          </Visible>
          {
            carouselImages.length > 0 && (
            <CarConfiguratorCarousel
              slides={
                carouselImages?.map((object) => (
                  <img
                    className='image-carousel-slide'
                    src={object.url}
                    alt={object.key}
                  />
                ))
              }
            />
            )
          }
          <Visible when={isMobile}>
            <div className='floating-wrap-picker-container'>
              {wrapPicker}
            </div>
            {selectedVehicleInfo}
          </Visible>
        </div>
      </div>
    </div>
  );
};


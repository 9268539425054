import React from 'react';
import { Carousel } from 'mod-styleguide';
import './car-configurator-carousel.scss';

interface PropType {
  slides: React.ReactNode[]
}

export const CarConfiguratorCarousel: React.FC<PropType> = ({
  slides,
}: PropType) => {
  const mappedSlides = slides.map((node) => (
    node as JSX.Element
  ));

  return (
    <Carousel
      className='car-configurator-image-carousel-v2'
      useArrows
      hideUnnecessaryArrows
      slides={mappedSlides}
    />
  );
};

import { useAppDispatch } from 'lib/types/redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { carConfiguratorSelector, selectConfiguratorVehicleV2 } from 'redux/modules/carConfigurator/carConfiguratorSlice';
import './selected-vehicle-info.scss';
import { VehicleModelV2 } from 'lib/types';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Button } from 'mod-styleguide';
import { OrderFormModalV2 } from 'components/order-form-modal-v2';
import { useBoolean } from 'ahooks';
import { SelectedVehicleInfoField } from '../selected-vehicle-info-field';

// TODO - move to CMS
export const VEHICLE_MODELS = {
  Tacoma24: {
    make: 'Toyota',
    model: 'Tacoma',
    year: 2024,
    s3FolderSlug: 'tacoma-24',
  },
  Tacoma22: {
    make: 'Toyota',
    model: 'Tacoma',
    year: 2022,
    s3FolderSlug: 'tacoma-22',
  },
};

export const SelectedVehicleInfo: React.FC = () => {
  const { displayInfo } = useSelector(carConfiguratorSelector);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [isOrderModalOpen, {
    setFalse: closeOrderModal,
    setTrue: openOrderModal,
  }] = useBoolean(false);
  const onSelectVehicle = (selectedVehicle: VehicleModelV2) => {
    dispatch(selectConfiguratorVehicleV2({
      vehicleModel: selectedVehicle,
    }));
  };

  const handleOrderClick = () => {
    openOrderModal();
  };

  return (
    <div className="selected-vehicle-info-v2-rct-component">
      <div className='selected-vehicle-info__first_row'>
        <div className='selected-vehicle-info'>
          <SelectedVehicleInfoField
            title={formatMessage({ id: 'car-configurator.vehicle-label' })}
            value="Toyota Tacoma"
          />
          <div className='vehicle-model-buttons'>
            {
              Object.values(VEHICLE_MODELS).map((vehicleModel) => {
                const isSelected = displayInfo?.model === vehicleModel.model && displayInfo?.year === vehicleModel.year;
                return (
                  <div
                    key={vehicleModel.year}
                    onClick={() => onSelectVehicle(vehicleModel)}
                    className="vehicle-model-button"
                  >
                    <div className={classNames('vehicle-model-button__content', {
                      'selected': isSelected,
                    })}
                    >
                      {vehicleModel.year}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <hr className='horizontal-content-divider' />
        <div className='selected-vehicle-info__pricing'>
          <SelectedVehicleInfoField
            title={formatMessage({ id: 'car-configurator.total-cost' })}
            value="+ $1500.00"
          />
        </div>
      </div>
      <div className='selected-vehicle-info__second_row'>
        <div className='floating-order-button-container'>
          <Button
            className='floating-order-button-container__button'
            label={formatMessage({ id: 'common.order-now' })}
            onClick={handleOrderClick}
            showDefaultIcon
          />
        </div>
      </div>
      <OrderFormModalV2
        isOpen={isOrderModalOpen}
        onClose={closeOrderModal}
      />
    </div>
  );
};


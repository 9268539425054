import { defineMessages } from 'react-intl';

export const COMMON = defineMessages({
  CANCEL: {
    id: 'common.cancel',
    description: 'Cancel text',
    defaultMessage: 'Cancel',
  },
  COPY: {
    id: 'common.copy',
    description: 'Copy text',
    defaultMessage: 'Copy',
  },
  CREATE: {
    id: 'common.create',
    description: 'Create text',
    defaultMessage: 'Create',
  },
  DONE: {
    id: 'common.done',
    description: 'Done text',
    defaultMessage: 'Done',
  },
  FIRST_NAME: {
    id: 'common.first-name',
    description: 'First name text',
    defaultMessage: 'First name',
  },
  LAST_NAME: {
    id: 'common.last-name',
    description: 'Last name text',
    defaultMessage: 'Last name',
  },
  PHONE_NUMBER: {
    id: 'common.phone-number',
    description: 'Phone number text',
    defaultMessage: 'Phone number',
  },
  EMAIL_ADDRESS: {
    id: 'common.email-address',
    description: 'Email text',
    defaultMessage: 'Email address',
  },
  LOGIN: {
    id: 'common.login',
    description: 'Login text',
    defaultMessage: 'Login',
  },
  CONFIRM_PASSWORD: {
    id: 'common.confirm-password',
    description: 'Confirm password text',
    defaultMessage: 'Confirm password',
  },
  PASSWORD: {
    id: 'common.password',
    description: 'Password text',
    defaultMessage: 'Password',
  },
  ADDRESS: {
    id: 'common.address',
    description: 'Address text',
    defaultMessage: 'Address',
  },
  FORGOT_PASSWORD: {
    id: 'common.forgot-password',
    description: 'Forgot password text',
    defaultMessage: 'Forgot password?',
  },
  FORGOT_PASSWORD_TITLE: {
    id: 'common.forgot-password-title',
    description: 'Forgot password title text',
    defaultMessage: 'Forgot password',
  },
  DONT_HAVE_AN_ACCOUNT: {
    id: 'common.dont-have-an-account',
    description: 'Don\'t have an account text',
    defaultMessage: 'Don\'t have an account?',
  },
  SIGN_UP: {
    id: 'common.sign-up',
    description: 'Sign up text',
    defaultMessage: 'Sign up',
  },
  DIVE_INTO_MODDING: {
    id: 'common.dive-into-modding',
    description: 'Dive into modding text',
    defaultMessage: 'Want to just dive into configuring your car?',
  },
  START_MODDING: {
    id: 'common.start-modding',
    description: 'Start modding text',
    defaultMessage: 'Start Modding',
  },
  START: {
    id: 'common.start',
    description: 'Start text',
    defaultMessage: 'Start',
  },
  SUBMIT: {
    id: 'common.submit',
    description: 'Submit text',
    defaultMessage: 'Submit',
  },
  TOS: {
    id: 'common.tos',
    description: 'Terms of Service text',
    defaultMessage: 'Terms of Service',
  },
  YEAR: {
    id: 'common.year',
    defaultMessage: 'YEAR',
  },
  MAKE: {
    id: 'common.make',
    defaultMessage: 'MAKE',
  },
  MODEL: {
    id: 'common.model',
    defaultMessage: 'MODEL',
  },
  TRIM: {
    id: 'common.trim',
    defaultMessage: 'TRIM',
  },
  COLOR: {
    id: 'common.color',
    defaultMessage: 'COLOR',
  },
  INPUT_FIELD_PLACEHOLDER: {
    id: 'common.input-field-placeholder',
    defaultMessage: 'Input field',
  },
  LOGOUT: {
    id: 'common.logout',
    defaultMessage: 'Logout',
  },
  PROFILE: {
    id: 'common.profile',
    defaultMessage: 'Profile',
  },
  OVERVIEW: {
    id: 'common.overview',
    defaultMessage: 'Overview',
  },
  MY_ACCOUNT: {
    id: 'common.my-account',
    defaultMessage: 'My Account',
  },
  CHECKOUT: {
    id: 'common.checkout',
    defaultMessage: 'Checkout',
  },
  BRAND: {
    id: 'common.brand',
    defaultMessage: 'Brand',
  },
  MATERIAL: {
    id: 'common.material',
    defaultMessage: 'Material',
  },
  SELECTED: {
    id: 'common.selected',
    defaultMessage: 'Selected',
  },
  APPLY: {
    id: 'common.apply',
    defaultMessage: 'Apply',
  },
  ALL: {
    id: 'common.all',
    defaultMessage: 'All',
  },
  LOCATION: {
    id: 'common.location',
    defaultMessage: 'Location',
  },
  ID: {
    id: 'common.id',
    defaultMessage: 'ID',
  },
  CUSTOMER: {
    id: 'common.customer',
    defaultMessage: 'Customer',
  },
  DEALER: {
    id: 'common.dealer',
    defaultMessage: 'Dealer',
  },
  INSTALLER: {
    id: 'common.installer',
    defaultMessage: 'Installer',
  },
  INSTALLER_CONTACT: {
    id: 'common.installer-contact',
    defaultMessage: 'Installer Contact',
  },
  PROJECT_DETAILS: {
    id: 'common.project-details',
    defaultMessage: 'Project Details',
  },
  STATUS: {
    id: 'common.status',
    defaultMessage: 'Status',
  },
  ETA: {
    id: 'common.eta',
    defaultMessage: 'ETA',
  },
  OR: {
    id: 'common.or',
    defaultMessage: 'or',
  },
  BODY: {
    id: 'common.body',
    defaultMessage: 'Body',
  },
  HOOD: {
    id: 'common.hood',
    defaultMessage: 'Hood',
  },
  TAILGATE: {
    id: 'common.tailgate',
    defaultMessage: 'Tailgate',
  },
  VEHICLE: {
    id: 'common.vehicle',
    defaultMessage: 'Vehicle',
  },
  TOTAL_COST: {
    id: 'common.total-cost',
    defaultMessage: 'Total Cost',
  },
  CONFIGURATOR_LINK: {
    id: 'common.configurator-link',
    defaultMessage: 'Configurator Link',
  },
  VIEW_BUILD: {
    id: 'common.view-build',
    defaultMessage: 'View Build',
  },
  CUSTOMER_NAME: {
    id: 'common.customer-name',
    defaultMessage: 'Customer Name',
  },
  ORDER: {
    id: 'common.order',
    defaultMessage: 'Order',
  },
  ORDER_NOW: {
    id: 'common.order-now',
    defaultMessage: 'Order Now',
  },
  PLACE_ORDER: {
    id: 'common.place-order',
    defaultMessage: 'Place Order',
  },
  ORDER_SUBMITTED: {
    id: 'common.order-submitted',
    defaultMessage: 'Order Submitted',
  },
});
